import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database' 
import { getAuth } from 'firebase/auth'

// Initialize Firebase
const fb = initializeApp({
  apiKey: 'AIzaSyDDTgQEg2XTcDAeFiGDNySSp_mPmBu5zgY',
  authDomain: 'food-next-69fc5.firebaseapp.com',
  projectId: 'food-next-69fc5',
  storageBucket: 'food-next-69fc5.appspot.com',
  messagingSenderId: '639408836718',
  appId: '1:639408836718:web:5a57ae967b3e13aac6567f',
  measurementId: 'G-LWHRKSZQG9'
})

export const db = getDatabase(fb)
export const auth = getAuth(fb)
