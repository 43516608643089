<div class="hello"><router-link to="/outer"><img class="small" src="/img/elf.png" alt="elf"></router-link><h1>這裡放一些對煮食、飲食的想法記錄</h1><div class="ui piled segment container" id="thin"><img src="/static/img/hsyinyi.jpg"><h2>對飲食的想法</h2><p>如果真的要說有什麼特別，我會比較注意除了米飯和麵粉，也以其他澱粉、五穀雜糧當主食，例如吃小米、芋頭、南瓜、地瓜、山藥當主食。</p><p>例如有天的早餐，是催芽綠豆與糙薏仁，加點南瓜、椰棗調味的清爽甜湯。</p><p>有時也煮燕麥，私心很喜歡燕麥粒的風味與口感，它也是黏性比較低的澱粉，吃起來感覺很舒服。請注意這邊是說燕麥粒，不是燕麥片，燕麥粒好煮好嚼，可Ｑ可軟，可甜可鹹可搭配米飯一起煮，很好用。</p><p>蓮藕高湯單煮非常好喝，混搭燕麥有點澱粉濃稠感也很棒，有時這就是我們家的主食！特別前陣子蓮藕產季，喜歡煮蓮藕燕麥湯！</p><p>什麼調味都不加就好喝極了！小孩喜歡這種雜糧或五穀根莖（例如牛蒡）的清湯，風味好，營養又好吸收。</p><p>我的經驗是，均衡的雜糧蔬食、不過度調味，比在意有沒有吃到肉啊蛋啊來得重要。^_^</p></div><!--.ui.piled.segment.container#thinimg(src="/static/img/hsyinyi.jpg")
p 我當初覺得
p 在醫院生產後，胎盤大多當廢棄物被處理掉吧
p 居家生，把胎盤當垃圾丟棄或放廚餘桶...感受上都怪怪的
p 我也不愛弄拓印（弄完一樣要丟掉／埋掉）

p 助產師說這有營養，也確實可食用，問我要不要吃
p 我說好啊

p 於是生產完當天就吃了一點
p 為了增加入口接受度，助產師教的料理是炒薑、米酒、醬油
p 她教老公怎麼做（分成小袋裝冷凍，直接丟鍋子炒）
p 然後我接著吃了三天的胎盤當早餐配菜

p 有人說胎盤超級營養
p 有人說胎盤有身體上的毒素，建議不要吃
p 每種說法各有它的理由
p 但我覺得最重要的是自己的想法
p 因為每個人的意念會讓身心是個動態的變化

p 好啦離題了

p 我本能覺得胎盤不是垃圾或廢棄物
p 也並不覺得這可愛的小生命到世界上
p 我產後就得吃掉很多動物『進補』
p 因此打算吃自己的，不浪費可利用的
p 沒道理可以『自補』卻不食，去吃別的動物吧

p 我覺得很多人因為在醫院生產
p 不太容易保存胎盤做料理
p 但還是可以參考下
p 貼過來分享：）--><div class="ui piled segment container" id="thin"><img src="/static/img/hsyinyi.jpg"><h2>吃夠了 VS 吃飽了</h2><p>小孩從小吃東西，我都是問他：吃夠了嗎？</p><p>我不問他『吃飽了嗎？』，因為『飽』其實是身體在表達胃塞不下了、感到勉強的訊號，人真的不需要從小被養成『習慣吃撐』的感覺，才認為這是『吃到了』。</p><p>大部分人都有這個經驗吧，小時候明明不太餓，或已經吃不下了，卻還得留在餐桌上，被要求碗裡的食物要吃完才能下桌（也可能開始討價還價）。</p><p>有了小孩後，我選擇較自在的飲食量、飲食節奏。</p><p>當然有時也希望他把碗裡的吃完:p 或多吃幾口~有時他願意，有時說：我已經吃夠了。或是：我睏了想先睡午覺，起來再吃。</p><p>練習去感覺自己的狀態跟需要。</p><p>我也比較少說『趕快吃』，畢竟餓了進食是本能，慢慢嚼，吃夠了就好^^</p><p>大人要注意的就是食材的營養搭配、小孩的咀嚼習慣~</p><p>關於後者，我跟他解釋(順手拿起桌子旁的袋子)：</p><p>身體裡有個小袋子裝吞進去的食物，但袋子裡面沒有牙齒，牙齒是在外面幫忙咬碎，這樣吞進去，身體工作才比較舒服。</p><p>不然太大塊，你胃裡的袋子說：啊~我沒有牙齒咬不動，這個吃不動啊～～～有時肚子會脹脹的，感覺不舒服，那是身體在跟你講話。</p><p>有時小孩也會不想咬“有點難嚼”的東西，我說，你的牙齒在長大，他要咬有點硬的東西，才會變得強壯，才有力氣穩穩往上長。慢慢咬，吃地球媽媽的食物，你的牙齒跟身體會很有力量。</p><p>：）</p></div></div>