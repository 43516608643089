<div class="hello"><div class="ui piled segment container"><router-link to="/foods"><img class="float left" src="/img/elf.png" alt="elf"></router-link><h2 class="center">Ｈi 這裡用來存放Hsinyi的煮食紀錄</h2><h3 class="center">點「<router-link to="/foods">食譜</router-link>」可以看到簡記分享<br>FB分享請見粉絲頁-<a href="https://www.facebook.com/%E7%9C%9F%E8%94%AC%E6%9C%8D-110229097348056/" target="_blank" rel="noopener noreferrer"><i class="facebook icon"></i>真蔬服</a></h3><p class="main">本站蔬食比例較多，沒有特別標素不素，<br>料理靈感來自大地、靈感乍現，以及許多人分享的經驗與智慧。<br>飲食區打食材關鍵字（如：紅豆）可以找到相關的料理，<br>祝大家食在喜悅、自然~😀</p><div class="ui grid" v-if="foods.length &gt; 0"><div class="ui two column stackable row"><div class="column"><router-link :to="'/item/' + (foods.length - 1)"><h3>最新更新1：{{foods[foods.length - 1].t}}</h3><div v-if="foods[foods.length - 1].v"><video controls :src="'/static/videos/' + foods[foods.length - 1].v"></video></div><div class="square" v-if="foods[foods.length - 1].i" :style="{ 'background-image' : 'url(/static/images/' + foods[foods.length - 1].i + ')' }"></div></router-link></div><div class="column"><router-link :to="'/item/' + (foods.length - 2)"><h3>最新更新2：{{foods[foods.length - 2].t}}</h3><div v-if="foods[foods.length - 2].v"><video controls :src="'/static/videos/' + foods[foods.length - 2].v"></video></div><div class="square" v-if="foods[foods.length - 2].i" :style="{ 'background-image' : 'url(/static/images/' + foods[foods.length - 2].i + ')' }"></div></router-link></div></div><!-- .ui.two.column.stackable.row.column
  router-link(:to="'/item/' + (foods.length - 3)")
    h3 最新更新3：{{foods[foods.length - 3].t}}
    div(v-if ="foods[foods.length - 3].v")
      video(controls, :src="'/static/videos/' + foods[foods.length - 3].v")
    .square(v-if ="foods[foods.length - 3].i", :style = "{ 'background-image' : 'url(/static/images/' + foods[foods.length - 3].i + ')' }")
.column
  router-link(:to="'/item/' + (foods.length - 4)")
    h3 最新更新4：{{foods[foods.length - 4].t}}
    div(v-if ="foods[foods.length - 4].v")
      video(controls, :src="'/static/videos/' + foods[foods.length - 4].v")
    .square(v-if ="foods[foods.length - 4].i", :style = "{ 'background-image' : 'url(/static/images/' + foods[foods.length - 4].i + ')' }")--><!-- br--><!-- img.small.image(v-for="(image, i) in images", :src="image", :key="i", @click="index = i", alt="_")--><!-- vue-gallery-slideshow(:images="images", :index="index")--></div></div></div>