import { createRouter, createWebHistory } from 'vue-router'
import Intro from '@/components/Intro.vue'
import Item from '@/components/Item.vue'
import Tem from '@/components/Tem.vue'
import Ack from '@/components/Ack.vue'
import Thoughts from '@/components/Thoughts.vue'
import Baby from '@/components/Baby.vue'
import Outer from '@/components/Outer.vue'
import Diet from '@/components/Diet.vue'
import Videos from '@/components/Videos.vue'


const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/components/Admin.vue'),
  },
  {
    path: '/foods',
    name: 'Foods',
    component: () => import('@/components/Foods.vue'),
  },
  {
    path: '/images/:src',
    name: 'Image',
    component: () => import('@/components/Image.vue'),
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('@/components/My.vue'),
  },
  {
    path: '/snacks',
    name: 'Snack',
    component: () => import('@/components/Snack.vue'),
  },
  {
    path: '/tembei',
    name: 'Tembei',
    component: () => import('@/components/Tembei.vue'),
  },
  {
    path: '/item/:id',
    name: 'item',
    component: Item,
  },
  {
    path: '/tem/:id',
    name: 'tem',
    component: Tem,
  },
  {
    path: '/ack/:id',
    name: 'ack',
    component: Ack,
  },
  {
    path: '/thoughts',
    name: 'Thoughts',
    component: Thoughts,
  },
  {
    path: '/baby',
    name: 'Baby',
    component: Baby,
  },
  {
    path: '/outer',
    name: 'Outer',
    component: Outer,
  },
  {
    path: '/diet',
    name: 'Diet',
    component: Diet,
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
